<template>
  <svg class="arrow" xmlns="http://www.w3.org/2000/svg" :transform="rotate" width="12" height="8" viewBox="0 0 12 8" fill="currentColor">
    <path class="arrow" d="M6 0.5C6.85714 0.5 12 5.23846 12 6.53077C12 7.82308 0 7.82308 0 6.53077C0 5.23846 5.14286 0.5 6 0.5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'Arrow',
  props: {
    rotate: String,
  },
}
</script>
